<template>
    <div>
        <b-row v-if="errors.length > 0">
            <b-col cols="12">
                <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key ="index" variant="danger" dismissible>{{item.message}}</b-alert>
            </b-col>
        </b-row>
        <div v-if="state === 'loading'">
            <b-row align-h="center" align-v="center" class="full-row" >
                <b-spinner  style="width: 3rem; height: 3rem;"  type="grow" label="Loading..."></b-spinner>
            </b-row>
        </div>

        
        <div v-if="state === 'show'">
            <b-row>
                <b-col cols="12">
                    <p class="text-center">Please select an option below</p>
                </b-col>
            </b-row>
            <b-row v-for="(item, index) in devices" :key="index">
                <b-col cols="12" class="mt-1">
                    <b-card class="card-custom-padding text-center clickable"  @click="setAsActiveDevice(item)" :class="getClassForItem(item)">
                    <p>{{item.untSerial}} - {{item.untName}}</p>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data:() => ({
      state: 'loading',
        deviceSelected: null,
        devices: [],
        validationCode: null,
        errors: []
  }),
  created(){
      if (localStorage.getItem('selectedDevice') !== null) {
          this.deviceSelected = {
              untId: localStorage.getItem('selectedDevice'),
              untName: localStorage.getItem('selectedDeviceName'),
              untSerial: localStorage.getItem('selectedDeviceSerial')
          }
      }
this.errors = []
      this.getList()
      .then((response) => {
          this.state = 'show'
          this.devices = response.data
      })
      .catch(() => {
          this.state = 'show'
          this.errors.push({message: 'Could not load list items'})
      })
  },
  methods: {
        ...mapActions(['getList']),
        getClassForItem(item){
            if (item !== null && this.deviceSelected !== null) {
                if (item.untId.toString() === this.deviceSelected.untId.toString()) {
                    return 'tertiary-background'
                }
                else{
                    return 'secondary-background'
                }
            }
            else {
                return 'secondary-background'
            }
        },
        setAsActiveDevice(selectedItem){
            localStorage.setItem('selectedDevice', selectedItem.untId )
            localStorage.setItem('selectedDeviceName', selectedItem.untName)
            localStorage.setItem('selectedDeviceSerial', selectedItem.untSerial)
            this.deviceSelected = selectedItem
            this.$store.commit('changeScreen', 'home')
            this.$router.push({path: '/home'})
        }
  }
}
</script>
